import * as React from "react";
import { Admin, CustomRoutes, Resource, useTranslate } from "react-admin";
import { QueryClient } from "react-query";

import dataProvider from "./http";
import authProvider from "./auth";
import MyLayout from "./layout/Layout";
// import Dashboard from "./Dashboard";
import i18nProvider from "./i18nProvider";

import "./App.scss";

import { OrderssList } from "./pages/orders";
import Distribute from "./pages/distribute/Distribute";
import MapDistribute from "./pages/distribute/MapDistribute";

import {
  DeliverymanCreate,
  DeliverymanEdit,
  DeliverymanList,
} from "./pages/deliverymen";

import { StationCreate, StationEdit, StationList } from "./pages/stations";

import { MerchantCreate, MerchantEdit, MerchantList } from "./pages/merchants";

import { lightTheme } from "./layout/themes";
import { Route } from "react-router-dom";
import { AdsCreate, AdsEdit, AdsList } from "./pages/advertisements";
import { PopUpAdsCreate, PopUpAdsEdit, PopUpAdsList } from "./pages/popupAds";
import {
  SplashAdsCreate,
  SplashAdsEdit,
  SplashAdsList,
} from "./pages/splashAds";

const App = () => {
  const translate = useTranslate();
  const locale = localStorage.getItem("lang");

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        staleTime: Infinity,
      },
    },
  });

  return (
    <div
      style={
        locale === "ug"
          ? { direction: "rtl", fontFamily: "UKIJ Tuz Kitab" }
          : { direction: "ltr" }
      }
      className={locale === "ug" ? "ug" : "zh"}
    >
      <Admin
        title=""
        theme={lightTheme}
        dataProvider={dataProvider}
        authProvider={authProvider}
        queryClient={queryClient}
        i18nProvider={i18nProvider}
        layout={MyLayout}
        disableTelemetry
      >
        <Resource
          options={{ label: translate("orders.title") }}
          name="order"
          list={OrderssList}
        />

        <Resource
          options={{ label: translate("merchant.title") }}
          name="merchant"
          list={MerchantList}
          create={MerchantCreate}
          edit={MerchantEdit}
        />
        <Resource
          options={{ label: translate("deliveryman.title") }}
          name="deliveryman"
          list={DeliverymanList}
          create={DeliverymanCreate}
          edit={DeliverymanEdit}
        />

        <Resource
          options={{ label: translate("station.title") }}
          name="station"
          list={StationList}
          create={StationCreate}
          edit={StationEdit}
        />

        <Resource
          options={{ label: translate("advertisements.slider") }}
          name="elan"
          list={AdsList}
          create={AdsCreate}
          edit={AdsEdit}
        />

        <Resource
          options={{ label: translate("advertisements.splash") }}
          name="elanslash"
          list={SplashAdsList}
          create={SplashAdsCreate}
          edit={SplashAdsEdit}
        />

        <Resource
          options={{ label: translate("advertisements.popup") }}
          name="elanpopup"
          list={PopUpAdsList}
          create={PopUpAdsCreate}
          edit={PopUpAdsEdit}
        />

        <Resource name="users" />

        <CustomRoutes>
          <Route path="/distribute" element={<Distribute />} />
          <Route path="/map-distribute" element={<MapDistribute />} />
        </CustomRoutes>
      </Admin>
    </div>
  );
};
export default App;
