import axios from "axios";
import qs from "qs";

import { buildBaseURL } from "../http";

let lang = () => {
  const language = localStorage.getItem("lang") ?? "ug";
  return language;
};
const authProvider = {
  login: ({ username, password }) => {
    const options = {
      url: buildBaseURL("v1", "takeout") + "agent/login",
      method: "POST",
      data: qs.stringify({
        username,
        password,
        client_id: "jcaf7DdWBUP23z0c",
        client_secret: "SrBxVWmWLMkLxxwlPsRWBXoe8AEcvHQw",
        grant_type: "password",
      }),
      headers: {
        accept: "application/json",
        "content-type": "application/x-www-form-urlencoded",
        "Accept-Language": lang(),
      },
    };
    return axios(options)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          const { data } = response;
          throw new Error(data.message);
        } else {
          const { data } = response;

          if (data.result) {
            localStorage.setItem("auth", JSON.stringify(data.data));
          } else {
            throw new Error(data.message);
          }
        }
      })
      .catch((error) => {
        console.log("🚀 ~ file: index.js ~ line 39 ~ error", error);
        const { response } = error;

        if (response.status === 422) {
          const { data } = response;
          return Promise.reject(data.message);
        } else {
          const { data } = response;
          console.log(
            "🚀 ~ file: index.js ~ line 49 ~ data.message",
            data.message
          );
          return Promise.reject(data.message);
          // throw new HttpError(data.message);
        }
      });
  },
  checkError: (error) => {
    console.log("🚀 ~ file: index.js ~ line 61 ~ error", error, error?.response);
    if (error.response) {
      const { status } = error.response;
      if (status === 401) {
        localStorage.removeItem("auth");
        return Promise.reject();
      }
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    if (authData) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { accountId, fullName } = JSON.parse(localStorage.getItem("auth"));
      return Promise.resolve({ id: accountId, fullName });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const authData = JSON.parse(localStorage.getItem("auth"));
    let role = "pending";
    if (authData?.accountId) {
      role = "admin";
    }
    return Promise.resolve(role);
  },
};

export default authProvider;
